import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { FaBarsStaggered } from 'react-icons/fa6';
import { HashLink as Link } from 'react-router-hash-link';
// import { useLocation } from 'react-router-dom';

export const Header = ({user}) => {
    // const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [orderId, setOrderId] = useState('');
    const [transactionId, setTransactionId] = useState('');
    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };

    // const isHome = location.pathname ==== '/home' || location.pathname ==== '/profile';

    return (
        <div className='flex flex-col xl:flex-row drop-shadow-md bg-white items-start xl:items-center justify-end px-8 md:px-16 py-4 navbar w-full flex-wrap sticky top-0 z-30 mx-auto'>
            <nav>
                <div className='hidden xl:flex items-center justify-center gap-8 font-semibold'>
                    <div className="flex items-center border-color p-3 border rounded-lg">
                        <img
                            src={require("../images/search.png")}
                            alt="search"
                            className=" w-6 mr-3"
                        />
                        <input
                            type="text"
                            id="orderId"
                            className="w-auto outline-none navbar"
                            placeholder="Search Transaction ID"
                            value={transactionId}
                            onChange={(e) => setTransactionId(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center border-color p-3 border rounded-lg">
                        <img
                            src={require("../images/search.png")}
                            alt="search"
                            className=" w-6 mr-3"
                        />
                        <input
                            type="text"
                            id="orderId"
                            className="w-auto outline-none navbar"
                            placeholder="Search Order Number"
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                        />
                    </div>

                    <Link to="/notification" smooth>
                        <img
                            src={require("../images/notification.png")}
                            alt="Profile"
                            className=" w-6"
                        />
                    </Link>

                    <p className='font-light'>Good {getTimeOfDay()}, <strong>{user?.user?.firstName} {user?.user?.lastName}</strong></p>

                    <div className='w-auto'>
                        <Link to="/profile" smooth>
                            <img
                                src={user?.user?.profileImage}
                                alt="Profile"
                                className="rounded-2xl h-10 w-10 object-cover"
                            />
                        </Link>
                    </div>
                </div>
                <div className='xl:hidden absolute top-7 right-8 md:right-16'>
                    <button onClick={toggleNavbar}>{isOpen ? <FaTimes className='text-2xl md:text-4xl choc-brown' /> : <FaBarsStaggered className='text-2xl md:text-4xl choc-brown' />}</button>
                </div>
            </nav>

            {isOpen &&
                <div className='flex flex-col gap-4 xl:gap-8 font-semibold'>
                    <div>
                        <ul className='flex flex-col choc-brown gap-4 mt-5'>
                            <li><Link to="/#about" onClick={toggleNavbar} smooth>About Us</Link></li>
                            {/* <li><Link to="/#FAQ" onClick={toggleNavbar} smooth>FAQs</Link></li> */}
                        </ul>
                    </div>

                    <div className='flex flex-col  justify-center gap-4 text-sm'>
                        <button className='border px-2 md:px-8 py-1 rounded choc-brown border-color'><Link to="/login" smooth onClick={toggleNavbar}>Login</Link></button>
                        <button className='button-background text-white px-2 py-1 rounded w-full'><Link to="/signup" smooth onClick={toggleNavbar}>Get Started</Link></button>
                    </div>
                </div>
            }
        </div>
    );
};

function getTimeOfDay() {
    const currentHour = new Date().getHours();
  
    if (currentHour >= 5 && currentHour < 12) {
      return 'Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Afternoon';
    } else {
      return 'Evening';
    }
  }