import React, { useState, useEffect } from 'react';
import CircularProgressBar from '../components/CircularProgressBar';
import endpoints from "../utils/endpoints"
import { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from 'react-router-dom';
import formattedCurrency from '../utils/FormartCurrency';
// import { Link } from 'react-router-dom';


const OrderDetails = ({ token }) => {
    const navigate = useNavigate()
    const [progress, setProgress] = useState(0);
    const [order, setOrder] = useState(null)

    //get param
    const { id } = useParams();

    useEffect(() => {
        getUser(id, token, setOrder)
    }, [id, token])

    useEffect(() => {
        // Simulating progress increase over time
        const interval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : 0));
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return (
        <>  {
            order ?
                <div className='h-full'>
                    <div className="flex w-full bg-white sticky top-0 z-10 items-center py-4">
                        <div className='navbar w-1/4 px-14'>
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => navigate(-1)} width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                            </svg>
                        </div>
                        <div className="flex flex-col ml-20 w-5/6">
                            <div className="flex items-center ml-10">
                                <h1 className="text-2xl font-semibold choc-brown">Order Items</h1>
                            </div>
                        </div>
                    </div>

                    <div className="flex px-14 w-full h-full">
                        <div className="flex flex-col bg-white w-1/4">
                            <div className='overflow-hidden flex flex-col flex-grow overflow-y-auto'>
                                <div className='flex items-center mt-4'>
                                    <h1 className="text-4xl  text mr-3">Order No. <span className='choc-brown'>{order.id.slice(-8).toLocaleUpperCase()}</span></h1>
                                </div>
                                <h1 className="text-2xl text-[#00810D] ">{order.status}</h1>

                                <ul className="mt-4">
                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Name of Customer<br /><span className='text'>{order.user.firstName} {order.user.lastName}</span></h1>
                                        </div>
                                    </li>
                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Vendor(s) Patronized<br /><span className='text'>{
                                                order.OrderShop.map((shop, index) =>
                                                    <span key={index}>{shop?.shop?.name}<span>  ({shop.status})</span>{index < order.OrderShop.length - 1 ? <br /> : ''}</span>
                                                )
                                            }</span></h1>
                                        </div>
                                    </li>

                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Courier Used<br /><span className='text'>{order.courier.firstName} {order.courier.lastName}</span></h1>
                                        </div>
                                    </li>

                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Delivery Address<br /><span className='text'>{order.deliveryAddress}</span></h1>
                                        </div>
                                    </li>

                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Date and Time of Last Activity<br />
                                                <span className='text'>{new Date(order.updatedAt).toLocaleDateString()}</span>
                                                <span className='choc-brown'> {new Date(order.updatedAt).toLocaleTimeString([], {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                    hour12: true,
                                                })}</span>
                                            </h1>
                                        </div>
                                    </li>

                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Price<br /><span className='text'>
                                                <span className="choc-brown">NGN</span>{formattedCurrency(order.OrderShop?.map((item) => {
                                                    return item.amount
                                                }).reduce((acc, cur) => {
                                                    return acc + cur
                                                }, 0)).replace('NGN', '')}</span></h1>
                                        </div>
                                    </li>

                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Courier Fee<br /><span className='text'>
                                                <span className="choc-brown">NGN</span>{formattedCurrency(order.OrderShop?.map((item) => {
                                                    return item.courierFee
                                                }).reduce((acc, cur) => {
                                                    return acc + cur
                                                }, 0)).replace('NGN', '')}</span></h1>
                                        </div>
                                    </li>

                                </ul>

                                {/* <div className='w-full mt-10'>
                                    <button style={{
                                        display: user.isDriver || user.hasShop ? 'block' : 'none'
                                    }}
                                        onClick={() => {
                                            setType(
                                                type == 'User' && user.hasShop ? 'Vendor' :
                                                    type == 'User' && user.isDriver ? 'Courier' :
                                                        type == 'Vendor' && user.isDriver ? 'Courier' :
                                                            'User'
                                            )
                                            if (type == 'User' || type == 'Vendor') {
                                                setAction('Check Order History')
                                            } else {
                                                setAction('Check Delivery History')
                                            }
                                        }} className="border border-[#F3684D] choc-brown font-semibold rounded-md px-4 py-2 w-full">Check {
                                            type == 'User' && user.hasShop ? 'Vendor' :
                                                type == 'User' && user.isDriver ? 'Courier' :
                                                    type == 'Vendor' && user.isDriver ? 'Courier' :
                                                        'User'
                                        } Account</button>
                                    <button onClick={() => {
                                        setAction(
                                            action != 'Check Transaction History' ? 'Check Transaction History' :
                                                type == 'User' || type == 'Vendor' ? 'Check Order History' :
                                                    'Check Delivery History'
                                        )
                                    }} className="border border-[#F3684D] choc-brown font-semibold rounded-md px-4 py-2 mt-4 w-full">{action}</button>
                                </div> */}
                            </div>
                        </div>

                        <div className="flex ml-20 w-4/5">
                            <ul className='pt-10 w-[200px] sticky z-3'>
                                <li className='mt-4'>
                                    <h1 className="text-xl inactive-text">Description</h1>
                                </li>
                                <li className='mt-[70px]'>
                                    <h1 className="text-xl inactive-text">Item</h1>
                                </li>
                                <li className='mt-10'>
                                    <h1 className="text-xl inactive-text">Item Category</h1>
                                </li>
                                <li className='mt-8'>
                                    <h1 className="text-xl inactive-text">Vendor</h1>
                                </li>
                                <li className='mt-8'>
                                    <h1 className="text-xl inactive-text">Quantity</h1>
                                </li>
                                <li className='mt-8'>
                                    <h1 className="text-xl inactive-text">Color</h1>
                                </li>
                                <li className='mt-8'>
                                    <h1 className="text-xl inactive-text">Distance</h1>
                                </li>
                                <li className='mt-8'>
                                    <h1 className="text-xl inactive-text">Price</h1>
                                </li>
                            </ul>
                            <div className='overflow-hidden flex-grow overflow-x-auto'>
                                <ul className='flex gap-4 mt-4'>
                                    {
                                        order.OrderShop.map((orderShop, index) =>
                                            orderShop.orderItems.map((item, index) =>
                                                <li key={index} className='flex flex-col '>
                                                    <div className='flex flex-col w-full h-32 w-[400px]'>
                                                        <div className='flex'>
                                                            <img
                                                                src={item.image}
                                                                alt="product"
                                                                className="rounded-lg h-20 w-26 object-cover"
                                                            />
                                                            <h1 className="text inactive-text ml-4">{item.product.description}</h1>
                                                        </div>
                                                        <h1 className="text-xl text mt-1">{orderShop.status}</h1>
                                                    </div>
                                                    <div className='flex flex-col mt-4'>
                                                        <h1 className="text-xl">{item.name}</h1>
                                                    </div>
                                                    <div className='flex flex-col mt-8'>
                                                        <h1 className="text-xl">{item.product?.category?.name}</h1>
                                                    </div>
                                                    <div className='flex flex-col mt-8'>
                                                        <h1 className="text-xl">{orderShop.shop.name}</h1>
                                                    </div>
                                                    <div className='flex flex-col mt-8'>
                                                        <h1 className="text-xl">{item.quantity}</h1>
                                                    </div>
                                                    <div className='flex flex-col mt-8'>
                                                        <h1 className="text-xl"><div style={{
                                                            height: '24px',
                                                            width: '24px',
                                                            backgroundColor: item.color,
                                                            marginTop: '9px',
                                                        }}/></h1>
                                                    </div>
                                                    <div className='flex flex-col mt-8'>
                                                        <h1 className="text-xl">{orderShop.distance}km</h1>
                                                    </div>
                                                    <div className='flex flex-col mt-8'>
                                                        <h1 className="text-xl"><span className="choc-brown">NGN</span>{formattedCurrency(item.amount).replace('NGN', '')}</h1>
                                                    </div>
                                                </li>
                                            )
                                        )
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="flex w-full h-4/5 justify-center items-center">
                    <CircularProgressBar percentage={progress} />
                </div>
        }
            <Toaster />
        </>
    )
}

export default OrderDetails


async function getUser(id, token, setUser) {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.order + `/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log('order', data)
        if (response.ok) {
            setUser(data)
        }
    } catch (error) {
        console.log(error)
    }
}