
const Post = () => {

    return (
        <>
        <div>post</div>
        </>
    );
};

export default Post;
