import endpoints from "../utils/endpoints";
import { useState, useEffect } from "react";
import formattedCurrency from "../utils/FormartCurrency";
import { useNavigate } from "react-router-dom";
import CircularProgressBar from "./CircularProgressBar";


const OrderScreen = ({ user }) => {
    const navigate = useNavigate();
    const [type, setType] = useState('User')
    const [orders, setOrders] = useState(null)
    const [search, setSearch] = useState('')
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        // setType(user?.user?.role)
        setType('User')
    }, [user])

    useEffect(() => {
        getOrders(setOrders, user.access_token, search)
    }, [user.access_token, search])

    useEffect(() => {
        // Simulating progress increase over time
        const interval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : 0));
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return (
        <> {orders ?
            <div className="flex flex-col px-10 py-8">
                <div className="flex items-center ml-10 mb-6 justify-between">
                    <div className="flex items-center">
                        <h1 className="text-2xl font-semibold choc-brown">All Orders</h1>
                        <div className="p-3 background rounded ml-8">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                            </svg>
                        </div>
                    </div>
                    <div className="flex items-center bg-white drop-shadow p-2 border rounded-lg">
                        <img
                            src={require("../images/search.png")}
                            alt="search"
                            className=" w-4 mr-3"
                        />
                        <input
                            type="text"
                            id="orderId"
                            className="w-auto outline-none navbar"
                            placeholder="Search Order No."
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                        />
                    </div>
                </div>
                <div className="">
                    <table class="table-auto w-full overflow-y-auto">
                        <thead className="inactive-text">
                            <tr>
                                <th className="text-start py-2 px-4">Order No.</th>
                                <th className="text-start py-2 px-4">{'Vendor'}</th>
                                <th className="text-start py-2 px-4">{'Courier'}</th>
                                <th className="text-start py-2 px-4">{'Customer'}</th>
                                <th className="text-start py-2 px-4">Date</th>
                                <th className="text-start py-2 px-4">Time</th>
                                <th className="text-start py-2 px-4">Price</th>
                                <th className="text-start py-2 px-4">Order Status</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {
                                (orders)?.map((data, index) =>
                                    <tr onClick={() => navigate(`/orders/${data?.id}`)}
                                    className="hover:bg-[#D9D9D9] rounded-lg" key={index}>
                                        <td class="py-6 px-4">{data?.id}</td>
                                        <td class="py-6 px-4">{data?.OrderShop?.map((item) => {
                                            return item.shop.name + ', '
                                        })}</td>
                                        <td class="py-6 px-4">{`${data?.courier?.firstName ?? 'Not'} ${data?.courier?.lastName ?? 'Assigned'}`}</td>
                                        <td class="py-6 px-4">{`${data?.user?.firstName} ${data?.user?.lastName}`}</td>
                                        <td class="py-6 px-4">{data.createdAt.split("T")[0]}</td>
                                        <td class="py-6 px-4">{new Date(data.createdAt).toLocaleTimeString([], {
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                            hour12: true,
                                        })}</td>
                                        <td class="py-6 px-4"><span className="choc-brown">NGN</span>{formattedCurrency(type !== 'Vendor' ? data?.OrderShop?.map((item) => {
                                            return type === 'User' ? item.amount : item.courierFee
                                        }).reduce((acc, cur) => {
                                            return acc + cur
                                        }, 0) : data?.amount ?? 0).replace('NGN', '')}</td>
                                        <td style={{
                                            color: data.status === 'SUCCESS' ? '#2F80ED' : '#EB5757'
                                        }} class="py-6 px-4">{data.status}</td>
                                    </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            :
            <div className="flex w-full h-4/5 justify-center items-center">
                <CircularProgressBar percentage={progress} />
            </div>
        }
        </>
    )
}

export default OrderScreen;



//get ordders
const getOrders = (setOrders, token, search) => {
    const path = search !== '' ? `${search}` : '%20'
    try {
        fetch(endpoints.baseUrl + endpoints.orders + path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        }).then(async response => {
            const data = await response.json()
            if (response.ok) {
                setOrders(data.data)
            }
            console.log(data.data)
        })
    }
    catch (err) {
        console.log(err)
    }
}