import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import logo from '../images/logo.png'
import { useAuth } from '../utils/auth';
import toast from "react-hot-toast";
import { Toaster } from "react-hot-toast";



const LoginPage = () => {
    // const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, status } = useAuth();


    const handleLogin = () => {
        if (!email || !password) return toast.error("Please fill all fields")
        if (status === 'loading') return toast.error("Loading")
        login({ email: email, password });


        // fetch(endpoints.baseUrl + endpoints.login, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         "email": email,
        //         "password": password
        //       })
        // })
        //     .then(async response => {
        //         const data = await response.json()
        //         if (response.ok) {
        //             navigate('/');
        //         }
        //         console.log(response)
        //         if (data.message === undefined) {
        //             alert(data.username || data.password)
        //         } else {
        //             alert(data.message)
        //         }
        //         console.log(data)
        //     })
        //     .catch(err => console.log(err))
    };

    return (
        <div
            className="flex items-center justify-center h-screen"
        >
            <div className='background md:flex h-screen w-1/2 justify-end relative hidden'>
                <img
                    src={require("../images/loginImage.png")}
                    alt="logo"
                    className="absolute w-4/5 -right-20"
                />
            </div>
            <div className='bg-white flex h-screen w-1/2 flex-col items-center'>
                <div className='flex h-3/4 w-full flex-col justify-center items-center'>
                    <h2 className="text-4xl texxt-black font-semibold mb-4">Log in to your admin account</h2>
                    <div className="my-12 px-8 w-2/3">
                        <input
                            type="email"
                            id="email"
                            className="w-full choc-brown p-3 border rounded-br-lg"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            id="password"
                            className="w-full choc-brown p-3 border rounded-br-lg mt-6"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            className="w-full button-background text-white p-3 mt-8 rounded-br-lg"
                            onClick={handleLogin}
                        >
                            {status === "loading" ? "processing..." : "Proceed"}
                        </button>
                    </div>
                </div>
                <div className='justify-self-end'>
                    <img src={logo} alt="" className='h-20 pointer-events-none' />
                </div>
            </div>
            <Toaster />
        </div>
    );
};

export default LoginPage;
