import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../utils/auth";
import endpoints from "../utils/endpoints";

export const ProtectedRoute = ({ children }) => {
  const { user, setUser } = useAuth();

  useEffect(() => {
    getUser(user?.access_token, (data) => {
      setUser({
        ...user,
        user: data
      })
    })
  }, [])
  if (!user || user.user.userType !== 'ADMIN') {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};


async function getUser(token, setUser) {
  try {
    const response = await fetch(endpoints.baseUrl + endpoints.profile, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    })
    const data = await response.json()
    console.log(data)
    if (response.ok) {
      setUser(data)
    }
  } catch (error) {
    console.log(error)
  }
}