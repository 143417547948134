import { useEffect, useState } from "react";
import Tab from "../components/Tab";
import { useLocation } from "react-router-dom";
import Summary from "../components/Summary";
import endpoints from "../utils/endpoints";
import Charts from "../components/Charts";
import Users from "../components/Users";
import { useAuth } from "../utils/auth";
import Categories from "../components/Categories";
import UserDetails from "../components/UserDetails";


const Dashboard = () => {
    const location = useLocation()
    const { user } = useAuth();
    const [currentTab, setCurrentTab] = useState('Overview')
    const [users, setUsers] = useState([])
    const regex = /^\/Users\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    useEffect(() => {
        getUsers(setUsers, user.access_token)
    }, [user.access_token])

    useEffect(() => {
        if (location.pathname !== '/') {
            const parentPath = location.pathname.split('/')[1]
            console.log('pathname', parentPath)
            setCurrentTab(parentPath)
        }
    }, [location])
    return (
        <>
            <Tab currentTab={currentTab} tabs={['Overview', 'Users', 'Categories']} />
            {
                currentTab === 'Overview' &&
                <>
                    <Summary users={users} />
                    <Charts users={users} token={user.access_token}/>
                </>
            }
            {
                currentTab === 'Users' && !regex.test(location.pathname) &&
                <>
                    <Users users={users} />
                </>
            },
            {
                currentTab === 'Categories' &&
                <>
                    <Categories user={user} />
                </>
            }

            {
                regex.test(location.pathname) &&
                <>
                    <UserDetails token={user.access_token}/>
                </>
            }
        </>
    );
};

export default Dashboard;



//get users
const getUsers = (setUsers, token) => {
    try {
        fetch(endpoints.baseUrl + endpoints.users, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        }).then(async response => {
            const data = await response.json()
            if (response.ok) {
                setUsers(data)
            }
            console.log(data)
        })
    }
    catch (err) {
        console.log(err)
    }
}