import { useEffect, useState } from "react";
import Tab from "../components/Tab";
import { useAuth } from "../utils/auth";
import SettingScreen from "../components/SettingScreen";

const Settings = () => {
    const { user } = useAuth();
    const [currentTab, setCurrentTab] = useState('')

    useEffect(() => {
        setCurrentTab('Administrators')
    }, [])

    return (
        <>
            <Tab currentTab={currentTab} tabs={['Administrators']} />
            <SettingScreen user={user} />
        </>
    );
};

export default Settings;
