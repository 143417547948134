import { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import endpoints from "../utils/endpoints";
import formattedCurrency from "../utils/FormartCurrency";
import moment from 'moment'


const Charts = ({ users, token }) => {
    const [TheYear, setTheYear] = useState(new Date().getFullYear());
    const [trendingCategory, setTrendingCategory] = useState(null)
    const [trendingVendor, setTrendingVendor] = useState(null)
    const [biggestCustomer, setBiggestCustomer] = useState(null)

    useEffect(() => {
        getTrendingCategory(setTrendingCategory, token)

        getTrendingVendor(setTrendingVendor, token)

        getBiggestCustomer(setBiggestCustomer, token)

    }, [token])

    // Group users by month
    const groupedByMonth = users.reduce((acc, item) => {
        const month = moment(item.createdAt).format('MMMM YYYY');
        acc[month] = (acc[month] || []).concat(item);
        return acc;
    }, {});


    const [state, setState] = useState({
        series: [
            {
                name: "Users",
                data: []
            },
            {
                name: "Vendors",
                data: []
            },
            {
                name: "Drivers",
                data: []
            },
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            colors: ['#F3684D', '#EE5064', '#7C117E'],
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                    opacity: 0.5
                },
            },
            yaxis: {
                title: {
                    text: 'Users'
                },
            },
            xaxis: {
                categories: [],
                title: {
                    text: 'Month'
                }

            }
        },
    })

    useEffect(() => {
        const months = Object.keys(groupedByMonth).map(month => month.split(' ')[0])

        // Get the number of users per month
        const usersByMonth = Object.keys(groupedByMonth).map(month => {
            return {
                name: month,
                value: groupedByMonth[month].length
            }
        });

        //get the number of vendors per month using hasShop in user object
        const vendorsByMonth = Object.keys(groupedByMonth).map(month => {
            return {
                name: month,
                value: groupedByMonth[month].filter(user => user.hasShop).length
            }
        });

        //get the number of drivers per month using isDriver in user object
        const driversByMonth = Object.keys(groupedByMonth).map(month => {
            return {
                name: month,
                value: groupedByMonth[month].filter(user => user.isDriver).length
            }
        });

        console.log('usersByMonth', Object.keys(groupedByMonth).map(month => month.split(' ')[0]))
        //update the state
        setState({
            series: [
                {
                    name: "Users",
                    data: usersByMonth.map(item => item.value)
                },
                {
                    name: "Vendors",
                    data: vendorsByMonth.map(item => item.value)
                },
                {
                    name: "Drivers",
                    data: driversByMonth.map(item => item.value)
                }
            ],
            options: {
                xaxis: {
                    categories: months,
                    title: {
                        text: 'Month'
                    }

                }
            }
        });
    }, [users, groupedByMonth])
    return (
        <div className="flex w-[1400px] gap-8 px-14 mb-12">
            <div className="w-1/2 drop-shadow-md bg-white p-6 rounded">
                <div className="card-header">
                    <div className="flex w-full justify-between">
                        <div>
                            <h4 >PERFORMANCE LINE CHART</h4>
                            <p className="inactive-text">Below is the line chart for the {TheYear}</p>
                        </div>
                        <select
                            id="assetdropdown"
                            name="asset"
                            aria-label="Example select with button addon"
                            value={TheYear}
                            onChange={(e) => setTheYear(e.target.value)}
                            className="border border-color rounded-lg bg-white px-4">
                            <option value="2021">Monthly</option>
                            <option value="2020">Yearly</option>
                        </select>
                    </div>
                </div>

                <div className="w-full">
                    <ReactApexChart options={state.options} series={state.series} type="line" height={350} />
                </div>
            </div>
            <div className="w-1/2 flex flex-col">
                <h2 className="self-end font-semibold">This week</h2>
                <ul>
                    <li>
                        <div style={{
                            backgroundColor: 'rgba(165, 165, 165, 0.08)'
                        }} className="flex flex-col px-6 py-4 rounded my-4">
                            <h1 className="font-semibold text-xl">Trending Category</h1>
                            <p className="inactive-text font-semibold">{trendingCategory?.name ?? '-----'}</p>
                            <h1 className="font-semibold text-xl self-end">{trendingCategory?.count.toLocaleString() ?? '--,---,---'} <span className="choc-brown">Users</span></h1>
                        </div>
                    </li>
                    <li>
                        <div style={{
                            backgroundColor: 'rgba(165, 165, 165, 0.08)'
                        }} className="flex flex-col px-6 py-4 rounded my-4">
                            <h1 className="font-semibold text-xl">Trending Vendor</h1>
                            <p className="inactive-text font-semibold">{trendingVendor?.shop?.name ?? '---- ----'}</p>
                            <h1 className="font-semibold text-xl self-end">{trendingVendor?.totalOrders.toLocaleString() ?? '-,---,---'} <span className="choc-brown">Orders</span></h1>
                        </div>
                    </li>
                    <li>
                        <div style={{
                            backgroundColor: 'rgba(165, 165, 165, 0.08)'
                        }} className="flex flex-col px-6 py-4 rounded my-4">
                            <h1 className="font-semibold text-xl">Biggest Customer</h1>
                            <p className="inactive-text font-semibold">{biggestCustomer?.user?.firstName ?? '----'} {biggestCustomer?.user?.lastName ?? '----'}</p>
                            <h1 className="font-semibold text-xl self-end"><span className="choc-brown">NGN</span>{formattedCurrency(biggestCustomer?.totalAmount).replace('NGN', '')} <span className="choc-brown">Spent</span></h1>
                        </div>
                    </li>
                </ul>
            </div>
        </div >
    )
}

export default Charts;


//get trending category
const getTrendingCategory = (setCategry, token) => {
    try {
        fetch(endpoints.baseUrl + endpoints.trendingCategory, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        }).then(async response => {
            const data = await response.json()
            if (response.ok) {
                setCategry(data)
            }
            console.log(data)
        })
    }
    catch (err) {
        console.log(err)
    }
}

//get trending vendor
const getTrendingVendor = (setVendor, token) => {
    try {
        fetch(endpoints.baseUrl + endpoints.trendingVendor, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        }).then(async response => {
            const data = await response.json()
            if (response.ok) {
                setVendor(data)
            }
            console.log(data)
        })
    }
    catch (err) {
        console.log(err)
    }
}

//get biggest customer
const getBiggestCustomer = (setCustomer, token) => {
    try {
        fetch(endpoints.baseUrl + endpoints.biggestCustomer, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        }).then(async response => {
            const data = await response.json()
            if (response.ok) {
                setCustomer(data)
            }
            console.log(data)
        })
    }
    catch (err) {
        console.log(err)
    }
}