import formattedCurrency from "../utils/FormartCurrency";
import { useNavigate } from "react-router-dom";


const Users = ({ users = [] }) => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col px-10 py-8">
            <div className="flex items-center ml-10 mb-6">
                <h1 className="text-2xl font-semibold choc-brown">Users</h1>
                <div className="p-3 background rounded ml-8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-funnel-fill" viewBox="0 0 16 16">
                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                    </svg>
                </div>
            </div>
            <div className="">
                <table class="table-auto w-full">
                    <thead className="inactive-text">
                        <tr>
                            <th className="text-start py-2 px-4">ID</th>
                            <th className="text-start py-2 px-4">First Name</th>
                            <th className="text-start py-2 px-4">Last Name</th>
                            <th className="text-start py-2 px-4">Email Address</th>
                            <th className="text-start py-2 px-4">Phone Number</th>
                            <th className="text-start py-2 px-4">Gender</th>
                            <th className="text-start py-2 px-4">Urua Balance</th>
                            <th className="text-start py-2 px-4">Account Type</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {
                            users.map((user, index) =>
                                <tr onClick={() => navigate(`/Users/${user?.id}`)}
                                 key={index} className="hover:bg-[#D9D9D9] rounded-lg">
                                    <td class="py-6 px-4">{user?.id}</td>
                                    <td class="py-6 px-4">{user?.firstName}</td>
                                    <td class="py-6 px-4">{user?.lastName}</td>
                                    <td class="py-6 px-4">{user?.email}</td>
                                    <td class="py-6 px-4">{user?.phoneNumber ?? 'N/A'}</td>
                                    <td class="py-6 px-4">{user?.gender}</td>
                                    <td class="py-6 px-4"><span className="choc-brown">NGN</span>{formattedCurrency(user?.balance).replace('NGN','')}</td>
                                    <td class="py-6 px-4">
                                        <div className="flex items-center">
                                            <div className="rounded-full bg-[#F3684D] h-4 w-4 mr-3" />
                                            <div style={{
                                                display: user?.hasShop ? 'block' : 'none'
                                            }}
                                                className="rounded-full bg-[#EE5064] h-4 w-4 mr-3" />
                                            <div style={{
                                                display: user?.isDriver ? 'block' : 'none'
                                            }}
                                                className="rounded-full bg-[#7C117E] h-4 w-4" />
                                        </div>
                                    </td>
                                </tr>)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Users;