import LoginPage from '../components/LoginPage';


const Login = () => {

    return (
        <>
            <LoginPage />
        </>
    );
};

export default Login;
