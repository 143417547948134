import { useEffect, useState } from "react";
import OrderScreen from "../components/OrdersScreen";
import { useAuth } from "../utils/auth";
import Tab from "../components/Tab";
import { useLocation } from "react-router-dom";
import OrderDetails from "../components/OrderDetails";


const Orders = () => {
    const location = useLocation()
    const { user } = useAuth();
    const [currentTab, setCurrentTab] = useState('')
    const regex = /^\/orders\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    useEffect(() => {
        setCurrentTab('All Orders')
    }, [])

    return (
        <>
            <Tab currentTab={currentTab} tabs={['All Orders']} />
            {
                !regex.test(location.pathname) &&
                <OrderScreen user={user} />
            }
            {
                regex.test(location.pathname) &&
                <>
                    <OrderDetails token={user.access_token} />
                </>
            }
        </>
    );
};

export default Orders;
