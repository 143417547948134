import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressBar = ({ percentage }) => {
  return (
    <div style={{ width: '100px', padding:5}}>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          textColor: '#F3684D',
          pathColor: `rgba(243, 104, 77,1)`,
          trailColor: '#d6d6d6',
        })}
      />
    </div>
  );
};

export default CircularProgressBar;
