import React, { useState, useEffect } from 'react';
import CircularProgressBar from '../components/CircularProgressBar';
import endpoints from "../utils/endpoints"
import toast, { Toaster } from "react-hot-toast";


const SettingScreen = ({ user }) => {
    const [admins, setAdmins] = useState(null)
    const [users, setUsers] = useState(null)
    const [progress, setProgress] = useState(0);
    const [newId, setId] = useState(null)
    const [status, setStatus] = useState("idle");
    const canProceed = newId

    //submit
    const handleSubmit = (e) => {
        e.preventDefault();
        setStatus("loading");

        addAdmin(newId, user.access_token, (data) => {
            getAdmins(setAdmins, user.access_token)
            setStatus("success");
            setId(null)
        })
    }

    //remove
    const handleRemoveAdmin = (id) => {
        setStatus("loading");
        removeAdmin(id, user.access_token, (data) => {
            getAdmins(setAdmins, user.access_token)
            setStatus("success");
        })
    }

    useEffect(() => {
        getAdmins(setAdmins, user.access_token)
        getUsers(setUsers, user.access_token)
    }, [user.access_token])

    useEffect(() => {
        // Simulating progress increase over time
        const interval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : 0));
        }, 100);

        return () => clearInterval(interval);
    }, []);
    return (
        <>  {
            admins && users ?
                <div className="flex px-14 w-full">
                    <div className="flex flex-col bg-white w-1/4 mt-8">
                        <h1 className="text-2xl font-semibold choc-brown">All Administrators</h1>
                        <ul className="mt-4">
                            {
                                admins.map((item, index) =>
                                    <li key={index} className="my-4">
                                        <div className='flex items-center w-2xl'>
                                            <div className="flex items-center">
                                                <img
                                                    src={item?.profileImage}
                                                    alt="category"
                                                    className="rounded-full h-10 w-10 object-cover"
                                                />
                                                <h2 className="ml-4 text-xl text font-semibold">{item?.firstName} {item?.lastName}</h2>
                                            </div>
                                            {
                                                item.email === user.user.email ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-person-lock ml-8" viewBox="0 0 16 16">
                                                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 5.996V14H3s-1 0-1-1 1-4 6-4c.564 0 1.077.038 1.544.107a4.524 4.524 0 0 0-.803.918A10.46 10.46 0 0 0 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664zM9 13a1 1 0 0 1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1" />
                                                    </svg>
                                                    :
                                                    <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleRemoveAdmin(item.id)} width="30" height="30" fill="currentColor" class="bi bi-trash choc-brown ml-8" viewBox="0 0 16 16">
                                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                                    </svg>
                                            }
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>

                    <div className="flex flex-col ml-20 w-1/2 mt-8">
                        <h1 className="text-2xl font-semibold choc-brown">Add an Administrator</h1>
                        <form className='mt-10 pl-10'>
                            <div className="flex flex-col">
                                <label className="text-[#858585] mb-2">Email</label>
                                <select
                                    className="w-full choc-brown border p-3 bg-white rounded-br-lg border-color"
                                    value={newId}
                                    onChange={(e) => setId(e.target.value)}
                                >
                                    <option value={null}>Select an email</option>
                                    {
                                        users.map((item, index) =>
                                            <option key={index} value={item.id}>{item.email}</option>
                                        )
                                    }
                                </select>

                            </div>

                            <div className='flex items-center'>
                                <button
                                    disabled={!canProceed}
                                    className="w-full button-background text-white p-3 mt-8 rounded-br-lg"
                                    onClick={handleSubmit}
                                >
                                    {status === "loading" ? "processing..." : "Proceed"}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                :
                <div className="flex w-full h-4/5 justify-center items-center">
                    <CircularProgressBar percentage={progress} />
                </div>
        }
            <Toaster />
        </>
    )
}

export default SettingScreen


const getAdmins = async (setAdmins, token) => {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.admins, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            setAdmins(data)
        }
    } catch (error) {
        console.log(error)
    }
}

const addAdmin = async (id, token, result = () => { }) => {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.addAdmin + id, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            result(data)
            toast.success("Admin added successfully");
        }
    } catch (error) {
        console.log(error)
    }
}

const removeAdmin = async (id, token, result = () => { }) => {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.removeAdmin + id, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            result(data)
            toast.success("Admin Removed successfully");
        }
    } catch (error) {
        console.log(error)
    }
}

//get users
const getUsers = (setUsers, token) => {
    try {
        fetch(endpoints.baseUrl + endpoints.users, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `bearer ${token}`
            }
        }).then(async response => {
            const data = await response.json()
            if (response.ok) {
                setUsers(data)
            }
            console.log(data)
        })
    }
    catch (err) {
        console.log(err)
    }
}