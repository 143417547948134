import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Login from './pages/Login';
import { AuthProvider } from './utils/auth';
import App from "./App";
import Splash from "./pages/Splash";
import './index.css';
import Dashboard from "./pages/Dashboard";
import Orders from "./pages/Orders";
import Post from "./pages/Post";
import Emails from "./pages/Emails";
import Settings from "./pages/Settings";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <AuthProvider>
        <App />
      </AuthProvider>
    ),
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "/:tab",
        element: <Dashboard />,
      },
      {
        path: "/Users/:id",
        element: <Dashboard />,
      },
      {
        path: '/orders',
        element: <Orders />,
      },
      {
        path: '/orders/:id',
        element: <Orders />,
      },
      {
        path: '/post',
        element: <Post />,
      },
      {
        path: '/emails',
        element: <Emails />,
      },
      {
        path: '/settings',
        element: <Settings />
      }
    ],
  },
  {
    path: "/login",
    element: (
      <AuthProvider>
        <Login />
      </AuthProvider>
    ),
  },
]);

const RootComponent = () => {
  const [showApp, setShowApp] = React.useState(false);

  useEffect(() => {
    // Simulate a delay for the splash screen
    const timer = setTimeout(() => {
      setShowApp(true);
    }, 10000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    showApp ? <RouterProvider router={router} /> : <Splash />
  );
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <RootComponent />
);
