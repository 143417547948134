const endpoints = {
    baseUrl: 'https://api.urua.africa',
    login: '/auth/sign-in/admin',
    users: '/admin/users',
    user: '/admin/user',
    categories: '/product/category',
    addCategory: '/admin/category',
    transactions: '/admin/transactions/',
    orders: '/admin/order/',
    shopOders: '/admin/shopOrders/',
    deleveries: '/admin/deliveries/',
    verify: '/admin/verifyDriver/',
    // orders: '/admin/search/orders/',
    trendingCategory: '/admin/highest-category',
    trendingVendor: '/admin/highest-oder',
    biggestCustomer: '/admin/highest-debit',
    order: '/order',
    profile: '/account/profile',
    admins: '/admin/admins',
    addAdmin: '/admin/add-admin/',
    removeAdmin: '/admin/remove-admin/',
}

export default endpoints;