
const Emails = () => {

    return (
        <>
        <div>emails</div>
        </>
    );
};

export default Emails;
