import { Outlet } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop';
import { ProtectedRoute } from './components/ProtectedRoute';
import Layout from './components/Layout';

function App() {
  return (
    <ProtectedRoute>
      <Layout>
        <ScrollToTop />
        <Outlet />
      </Layout>
    </ProtectedRoute>
  );
}

export default App;
