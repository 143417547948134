import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import toast from "react-hot-toast";
import endpoints from '../utils/endpoints';



export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const [status, setStatus] = useState("");

  const [user, setUser] = useLocalStorage("urua-user", null);
  const navigate = useNavigate();
  const location = useLocation();

  const [prevPath, setPrevPath] = useState("/");
  useEffect(() => {
    if (location.pathname !== "/login") {
      setPrevPath(location.pathname);
    }
  }, [location.pathname, prevPath]);

  // call this function to sign out logged in user

  useEffect(() => {
    if (user) setStatus("authenticated");
    else setStatus("unauthenticated");
  }, [status, user]);

  // call this function when you want to authenticate the user
  const login = async (data) => {
    try {
      setStatus("loading");
      let { email, password } = data;
      const res = await fetch(endpoints.baseUrl + endpoints.login, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: email, password: password }),
      });

      if (res.ok) {
        const user = await res.json();
        setStatus("authenticated");
        setUser({ ...user, password: undefined });
        setTimeout(() => {
          console.log("previous path", prevPath);
          navigate(prevPath);
        }, 100);
      } else {
        setUser(null);
        navigate("/login");
        const resp = await res.json();
        toast.error(resp.message || resp.error || "Error has occurred");
      }
    } catch (error) {
      console.log("error", error.message);
      toast.error("Error has occurred");
    }
  };

  const logout = async () => {
    setUser(null);
    setStatus("unauthenticated");
    navigate("/login", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      status,
      setUser,
    }),
    [user, status]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
