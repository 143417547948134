import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../utils/auth";
import { Header } from './Header';
import logo from '../images/logo2.png';


export default function Layout({ children }) {
  // const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  // const [notifications, setNotifications] = useState([]);



  //get notifications
  // useEffect(() => {
  //   fetch(`${api_base_url}/notifications`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // console.log('notifications', data);
  //       //check if data is a list
  //       if (Array.isArray(data)) {
  //         setNotifications(data);
  //       }
  //     });
  // }, []);

  //delete notification
  // const deleteNotification = (id) => {
    //remove notification from list
    // setNotifications(notifications.filter((item) => item._id !== id));
    // fetch(`${api_base_url}/notifications/${id}`, {
    //   method: "DELETE",
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // console.log('notifications', data);
    //     //check if data is a list
    //     if (Array.isArray(data)) {
    //       setNotifications(data);
    //     }
    //   });
  // };

  const parentPath = location.pathname.split('/')[1]

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="w-80 pt-5 h-full drop-shadow-xl bg-white justify-between flex flex-col sticky">
        <div>
          <div className="mb-[60px] drop-shadow-md bg-white px-8 pb-[25px]">
            <Link to="/" smooth>
              <div className=''>
                <img src={logo} alt="" className='w-56 pointer-events-none' />
              </div>
            </Link>
          </div>
          <ul className="px-8">
            <li>
              <Link to={'/'} className="flex items-center my-3 p-3 border rounded-lg pl-6"
                style={{
                  borderColor: (location.pathname === '/' || parentPath === 'Users'  || parentPath === 'Overview'  || parentPath === 'Categories') 
                  ? '#F3684D' : 'transparent'
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={location.pathname === '/' ? '#F3684D' : '#4F4F4F'} class="bi bi-columns-gap" viewBox="0 0 16 16">
                  <path d="M6 1v3H1V1zM1 0a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm14 12v3h-5v-3zm-5-1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zM6 8v7H1V8zM1 7a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zm14-6v7h-5V1zm-5-1a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1z" />
                </svg>
                <h5 style={{
                  color: location.pathname === '/' ? '#F3684D' : '#4F4F4F'
                }}
                  className="ml-3">Dashboard</h5>
              </Link>
            </li>
            <li>
              <Link to={'/orders'} className="flex items-center my-3 p-3 border rounded-lg pl-6"
                style={{
                  borderColor: parentPath === 'orders' ? '#F3684D' : 'transparent'
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={ parentPath === 'orders' ? '#F3684D' : '#4F4F4F'} class="bi bi-file-earmark-text" viewBox="0 0 16 16">
                  <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5" />
                  <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                </svg>
                <h5 style={{
                  color:  parentPath === 'orders' ? '#F3684D' : '#4F4F4F'
                }}
                  className="ml-3">Orders</h5>
              </Link>
            </li>
            {/* <li>
              <Link to={'/post'} className="flex items-center my-3 p-3 border rounded-lg pl-6"
                style={{
                  borderColor: location.pathname === '/post' ? '#F3684D' : 'transparent'
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={location.pathname === '/post' ? '#F3684D' : '#4F4F4F'} class="bi bi-file-post" viewBox="0 0 16 16">
                  <path d="M4 3.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5z" />
                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1" />
                </svg>
                <h5 style={{
                  color: location.pathname === '/post' ? '#F3684D' : '#4F4F4F'
                }}
                  className="ml-3">Post</h5>
              </Link>
            </li> */}
            {/* <li>
              <Link to={'/emails'} className="flex items-center my-3 p-3 border rounded-lg pl-6"
                style={{
                  borderColor: location.pathname === '/emails' ? '#F3684D' : 'transparent'
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={location.pathname === '/emails' ? '#F3684D' : '#4F4F4F'} class="bi bi-envelope" viewBox="0 0 16 16">
                  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z" />
                </svg>
                <h5 style={{
                  color: location.pathname === '/emails' ? '#F3684D' : '#4F4F4F'
                }}
                  className="ml-3">Emails</h5>
              </Link>
            </li> */}
            <li>
              <Link to={'/settings'} className="flex items-center my-3 p-3 border rounded-lg pl-6"
                style={{
                  borderColor: location.pathname === '/settings' ? '#F3684D' : 'transparent'
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={location.pathname === '/settings' ? '#F3684D' : '#4F4F4F'} class="bi bi-gear-fill" viewBox="0 0 16 16">
                  <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                </svg>
                <h5 style={{
                  color: location.pathname === '/settings' ? '#F3684D' : '#4F4F4F'
                }}
                  className="ml-3">Settings</h5>
              </Link>
            </li>
          </ul>
        </div>
        <div className="">
          <div className="drop-shadow py-6 bg-white w-full flex flex-col px-8">
            <div className="flex">
              <div style={{
                backgroundColor: '#F3684D',
                height: 20,
                width: 20,
                borderRadius: 10,
                marginRight: 10
              }} />
              <p className="inactive-text">Users/Customer Account</p>
            </div>
            <div className="flex mt-4">
              <div style={{
                backgroundColor: '#EE5064',
                height: 20,
                width: 20,
                borderRadius: 10,
                marginRight: 10
              }} />
              <p className="inactive-text">Vendors Account</p>
            </div>
            <div className="flex mt-4">
              <div style={{
                backgroundColor: '#7C117E',
                height: 20,
                width: 20,
                borderRadius: 10,
                marginRight: 10
              }} />
              <p className="inactive-text">Couriers Account</p>
            </div>
          </div>
          <div className="px-8 py-6 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar2-minus primary" viewBox="0 0 16 16">
              <path d="M5.5 10.5A.5.5 0 0 1 6 10h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5" />
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
              <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" />
            </svg>
            <p className="inactive-text ml-2">{new Date().toDateString()},
              <span className="choc-brown"> {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).split(' ')[0]} </span>
              {new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).split(' ')[1].toUpperCase()}</p>
          </div>
        </div>
      </div>

      {/* Main content */}
      <div className="flex flex-col flex-grow overflow-hidden">
        {/* Header */}
        <Header user={user} />

        {/* Main content area */}
        <div className="flex-grow overflow-y-auto">
          {/* Page content goes here */}
          {children}
        </div>
      </div>
    </div>
  );
}
