import React, { useState, useEffect } from 'react';
import CircularProgressBar from '../components/CircularProgressBar';
import endpoints from "../utils/endpoints"
import toast, { Toaster } from "react-hot-toast";


const Categories = ({ user }) => {
    const [categories, setCategories] = useState(null)
    const [progress, setProgress] = useState(0);
    const [newCategory, setNewCategory] = useState({
        name: '',
        icon: '',
        image: '',
        covers: [],
        iconName: '',
        imageName: ''
    })
    const [isEdit, setIsEdit] = useState(false)

    const [status, setStatus] = useState("idle");
    const canProceed = (newCategory.name.length > 0 && newCategory.iconName.length > 0 && newCategory.imageName.length > 0) || (isEdit && newCategory.name.length > 0);

    //handle change
    const handleChange = (e) => {
        if (e.target.name === 'covers') {
            const files = e.target.files;

            setNewCategory((prevCategory) => ({
                ...prevCategory,
                covers: [...prevCategory.covers, ...files],
            }));

        } else if (e.target.name === 'image' || e.target.name === 'icon') {
            const file = e.target.files[0]; // Assuming single file upload
            console.log(file)
            setNewCategory({
                ...newCategory,
                [e.target.name]: file,
                [`${e.target.name}Name`]: e.target.value
            });
        } else {
            setNewCategory({
                ...newCategory,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleRemove = (index) => {
        setNewCategory((prevCategory) => {
            const updatedCovers = [...prevCategory.covers];
            updatedCovers.splice(index, 1);
            return {
                ...prevCategory,
                covers: updatedCovers,
            };
        });
    };

    //edit
    const handleEdit = (category) => {
        setIsEdit(true)
        setNewCategory({
            id: category.id,
            name: category.name,
            icon: '',
            image: '',
            covers: [],
            iconName: '',
            imageName: ''
        })
    }

    //cancel 
    const handleCancel = (e) => {
        e.preventDefault();
        setIsEdit(false)
        setNewCategory({
            name: '',
            icon: '',
            image: '',
            covers: [],
            iconName: '',
            imageName: ''
        })
    }

    //submit
    const handleSubmit = (e) => {
        e.preventDefault();
        setStatus("loading");
        if (isEdit) {
            editCategory(newCategory, user.access_token, (data) => {
                getCategories(setCategories, user.access_token)
                setStatus("success");
                setIsEdit(false)
                setNewCategory({
                    name: '',
                    icon: '',
                    image: '',
                    covers: [],
                    iconName: '',
                    imageName: ''
                })
            })
        } else {
            addCategory(newCategory, user.access_token, (data) => {
                getCategories(setCategories, user.access_token)
                setStatus("success");
                setIsEdit(false)
                setNewCategory({
                    name: '',
                    icon: '',
                    image: '',
                    covers: [],
                    iconName: '',
                    imageName: ''
                })
            })
        }
    }


    const renderImagePreviews = () => {
        return newCategory.covers.map((file, index) => (
            <div key={index} className="flex flex-col items-center m-2 rounded">
                <img src={URL.createObjectURL(file)} alt={`preview-${index}`} className="h-24 mb-2 rounded" />
                <button onClick={() => handleRemove(index)} className="text-[#858585] hover:text-red-500">
                    Remove
                </button>
            </div>
        ));
    };
    useEffect(() => {
        getCategories(setCategories, user.access_token)
    }, [user.access_token])

    useEffect(() => {
        // Simulating progress increase over time
        const interval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : 0));
        }, 100);

        return () => clearInterval(interval);
    }, []);
    return (
        <>  {
            categories ?
                <div className="flex px-14 w-full">
                    <div className="flex flex-col bg-white w-1/4">
                        <h1 className="text-2xl font-semibold choc-brown">Categories</h1>
                        <ul className="mt-4">
                            {
                                categories.map((item, index) =>
                                    <li key={index} className="my-4">
                                        <div className='flex items-center w-2xl'>
                                            <div className="flex items-center justify-between border-color p-3 border rounded-lg w-full">
                                                <div className="flex items-center">
                                                    <img
                                                        src={item?.image}
                                                        alt="category"
                                                        className="rounded h-8 w-8 object-cover"
                                                    />
                                                    <h2 className="ml-4 text">{item?.name}</h2>
                                                </div>
                                                <p className="inactive-text">{item?.totalShops} Shps</p>
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => handleEdit(item)} width="30" height="30" fill="currentColor" class="bi bi-pencil-square choc-brown ml-8" viewBox="0 0 16 16">
                                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                                            </svg>
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>

                    <div className="flex flex-col ml-20 w-1/2">
                        <h1 className="text-2xl font-semibold choc-brown">{isEdit ? 'Edit Category' : 'Add A Category'}</h1>
                        <form className='mt-10 pl-10'>
                            <div className="flex flex-col">
                                <label className="text-[#858585] mb-2">Name Of Category</label>
                                <input
                                    type="text"
                                    id="name"
                                    name='name'
                                    className="w-full choc-brown p-3 border rounded-br-lg border-color"
                                    placeholder="e.g Food"
                                    value={newCategory.name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col mt-6">
                                <label className="text-[#858585] mb-2">Choose Category Icon</label>
                                <input
                                    type="file"
                                    id="icon"
                                    accept="image/jpeg, image/png"
                                    name='icon'
                                    className="w-full choc-brown p-3 border rounded-br-lg border-color"
                                    placeholder="Category icon"
                                    value={newCategory.iconName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="flex flex-col mt-6">
                                <label className="text-[#858585] mb-2">Choose Category Photo</label>
                                <input
                                    type="file"
                                    accept="image/jpeg, image/png"
                                    id="image"
                                    name='image'
                                    className="w-full choc-brown p-3 border rounded-br-lg border-color"
                                    placeholder="Category Image"
                                    value={newCategory.imageName}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full flex flex-col justify-center items-center py-16 bg-[#D9D9D9] p-3 border rounded-br-lg mt-6 relative">

                                {newCategory.covers.length === 0 ? (
                                    <>
                                        <input
                                            style={{ opacity: 0, height: '100%', width: '100%', position: 'absolute' }}
                                            type="file"
                                            accept="image/jpeg, image/png"
                                            name="covers"
                                            id="covers"
                                            multiple
                                            onChange={handleChange}
                                        />
                                        <img src={require('../images/addImage.png')} alt="logo" className="h-24 mb-2" />
                                        <p className="text-[#858585]">
                                            <span className="choc-brown">Click here</span> to add a covers
                                        </p>
                                    </>
                                ) : (
                                    <div className="flex flex-wrap">
                                        {renderImagePreviews()}
                                        <div style={{
                                            display: newCategory.covers.length >= 3 ? 'none' : 'flex'
                                        }}
                                            className='relative flex flex-col items-center mt-4'>
                                            <input
                                                style={{ opacity: 0, height: '100%', width: '100%', position: 'absolute' }}
                                                type="file"
                                                accept="image/jpeg, image/png"
                                                name="covers"
                                                id="covers"
                                                multiple
                                                onChange={handleChange}
                                            />
                                            <img src={require('../images/addImage.png')} alt="logo" className="h-14 mb-2" />
                                            <p className="choc-brown">
                                                Add more
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='flex items-center'>
                                <button
                                    disabled={!canProceed}
                                    className="w-full button-background text-white p-3 mt-8 rounded-br-lg"
                                    onClick={handleSubmit}
                                >
                                    {status === "loading" ? "processing..." : "Proceed"}
                                </button>

                                <button
                                    style={{
                                        display: isEdit ? 'block' : 'none'
                                    }}
                                    className="w-full button-background text-white p-3 mt-8 rounded-br-lg"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                :
                <div className="flex w-full h-4/5 justify-center items-center">
                    <CircularProgressBar percentage={progress} />
                </div>
        }
            <Toaster />
        </>
    )
}

export default Categories


const getCategories = async (setCategories, token) => {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.categories, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            setCategories(data)
        }
    } catch (error) {
        console.log(error)
    }
}

const addCategory = async (newCategory, token, result = () => { }) => {
    const form = new FormData()
    form.append(`name`, newCategory.name)
    newCategory.covers.forEach((cover, index) => {
        form.append(`covers`, cover);
    });
    form.append(`icon`, newCategory.icon)
    form.append(`image`, newCategory.image)
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.addCategory, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: form
        })
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            result(data)
            toast.success("Category added successfully");
        }
    } catch (error) {
        console.log(error)
    }
}

const editCategory = async (newCategory, token, result = () => { }) => {
    const form = new FormData()
    form.append('name', newCategory.name)
    form.append('icon', newCategory.icon)
    form.append('image', newCategory.image)
    newCategory.covers.forEach((cover, index) => {
        form.append(`covers`, cover);
    });
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.addCategory + `/${newCategory.id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            body: form
        })
        const data = await response.json()
        if (response.ok) {
            result(data)
            toast.success("Category edited successfully");
        }
    } catch (error) {
        console.log(error)
    }
}