import { Link } from "react-router-dom";

const Tab = ({path='', currentTab = 'Overview', tabs = ['Overview', 'Users', 'Categories'] }) => {
    return (
        <div className="flex w-full pt-5 bg-white drop-shadow sticky top-0 z-10 navbar">
            {
                tabs.map((item, index) =>
                    <Link to={`${path}/${item}`} key={index} className="flex flex-col items-center ml-24">
                        <strong style={{
                            color: currentTab === item ? '#F3684D' : '#858585'
                        }} className="font-semibold">{item}
                        </strong>
                        <div style={{
                            backgroundColor: currentTab === item ? '#F3684D' : 'transparent'
                        }} className="flex h-1 w-10 rounded mt-2" />
                    </Link>
                )
            }
        </div>
    )
}

export default Tab;