import React, { useState, useEffect } from 'react';
import CircularProgressBar from '../components/CircularProgressBar';
import endpoints from "../utils/endpoints"
import { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from 'react-router-dom';
import formattedCurrency from '../utils/FormartCurrency';
// import { Link } from 'react-router-dom';


const UserDetails = ({ token }) => {
    const navigate = useNavigate()
    const [transactions, setTransactions] = useState(null)
    const [orderHistory, setOrderHistory] = useState(null)
    const [shopOrders, setShopOrders] = useState(null)
    const [deliveryHistory, setDeliveryHistory] = useState(null)
    const [progress, setProgress] = useState(0);
    const [user, setUser] = useState(null)
    const [type, setType] = useState('User')
    const [action, setAction] = useState('Check Order History')
    const [tab, setTab] = useState('Transaction History')

    const [status, setStatus] = useState("idle");

    //get param
    const { id } = useParams();

    useEffect(() => {
        getTransactions(setTransactions, token, id)
        getOders(setOrderHistory, token, id)
        getShopOrders(setShopOrders, token, id)
        getDelivry(setDeliveryHistory, token, id)
        getUser(id, token, setUser)
    }, [id, token])

    useEffect(() => {
        // Simulating progress increase over time
        const interval = setInterval(() => {
            setProgress((prev) => (prev < 100 ? prev + 1 : 0));
        }, 100);

        return () => clearInterval(interval);
    }, []);
    return (
        <>  {
            transactions && (deliveryHistory || !user?.isDriver) && orderHistory && shopOrders && user ?
                <div className='h-full'>
                    <div className="flex w-full bg-white sticky top-0 z-10 items-center py-4">
                        <div className='navbar w-1/4 px-14'>
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={() => navigate(-1)} width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                            </svg>
                        </div>
                        <div className="flex flex-col ml-20 w-5/6">
                            <div className="flex items-center ml-10">
                                <h1 className="text-2xl font-semibold choc-brown">{tab}</h1>
                                <div className="p-3 background rounded ml-8">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-funnel-fill" viewBox="0 0 16 16">
                                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex px-14 w-full h-full">
                        <div className="flex flex-col bg-white w-1/4">
                            <div className='overflow-hidden flex flex-col flex-grow overflow-y-auto'>
                                <div className='flex items-center mt-4'>
                                    <h1 className="text-4xl font-semibold text mr-3">{user.firstName} <span className='choc-brown'>{user.lastName}</span></h1>
                                    <img
                                        src={user?.profileImage}
                                        alt="Profile"
                                        className="rounded-2xl h-10 w-10 object-cover"
                                    />
                                </div>
                                <h1 className="text-2xl text ">Urua<span style={{
                                    color: type === 'User' ? '#F3684D' : type === 'Vendor' ? '#EE5064' : '#7C117E'
                                }}>{type}{type === 'Vendor' && ' ('}<span style={{
                                    display: type === 'Vendor' ? 'contents' : 'none'
                                }}
                                    className='text'>{user?.Shop?.name}</span>{type === 'Vendor' && ')'}</span></h1>

                                <ul className="mt-4">
                                    <li>
                                        <div>
                                            <h1 className="text-xl inactive-text">{type === 'Vendor' ? 'Orders Recived' : type === 'User' ? 'Orders Made' : 'Job Completed'}<br />
                                                <span className='text'>
                                                    {type === 'Vendor' ? shopOrders.length : type === 'User' ? orderHistory.length : deliveryHistory.filter((it) => it.status === 'SUCCESS').length}
                                                </span>
                                            </h1>
                                        </div>
                                    </li>
                                    <li style={{
                                        display: type === 'Courier' ? 'block' : 'none'
                                    }} className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Driver's License<br /><a href={user.driverLicense} target='blank'>
                                                <span style={{
                                                    color: user.driverLicense ? '#2F80ED' : '#EB5757',
                                                    textDecoration: 'underline'
                                                }}>{user.driverLicense ? 'View License' : 'Not Uploaded'}</span>
                                            </a></h1>
                                        </div>
                                    </li>
                                    <li style={{
                                        display: type === 'Courier' ? 'block' : 'none'
                                    }} className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Account Status<br /><span style={{
                                                color: user.isDriverVerified ? '#2F80ED' : '#EB5757',
                                                display: type === 'Courier' ? 'contents' : 'none'
                                            }}>{user.isDriverVerified ? 'Verified' : 'Not Verified'}</span> <button style={{
                                                display: user.isDriverVerified ? 'none' : 'contents',
                                                textDecoration: 'underline'
                                            }} onClick={() => {
                                                if (status === 'idle') {
                                                    setStatus('pending')
                                                    verify(id, token, setUser)
                                                }
                                            }} className='choc-brown'>{status === 'idle' ? 'verify' : 'please wait...'}</button></h1>
                                        </div>
                                    </li>
                                    {/* <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Rating<br /></h1>
                                        </div>
                                    </li> */}
                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">E-mail Address<br /><span className='text'>{user.email}</span></h1>
                                        </div>
                                    </li>
                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Phone Number<br /><span className='text'>{user.phoneNumber}</span></h1>
                                        </div>
                                    </li>
                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Address<br /><span className='text'>{type === 'Vendor' ? user?.Shop.address : user?.address}</span></h1>
                                        </div>
                                    </li>
                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Gender<br /><span className='text'>{user.gender}</span></h1>
                                        </div>
                                    </li>
                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text">Urua Balance<br /><span className='text'>
                                                <span className="choc-brown">NGN</span>{formattedCurrency(user?.balance).replace('NGN', '')}</span></h1>
                                        </div>
                                    </li>
                                    <li className='mt-2'>
                                        <div>
                                            <h1 className="text-xl inactive-text ">Account Type</h1>
                                            <div className="flex items-center">
                                                <div className="rounded-full bg-[#F3684D] h-4 w-4 mr-3" />
                                                <div style={{
                                                    display: user?.hasShop ? 'block' : 'none'
                                                }}
                                                    className="rounded-full bg-[#EE5064] h-4 w-4 mr-3" />
                                                <div style={{
                                                    display: user?.isDriver ? 'block' : 'none'
                                                }}
                                                    className="rounded-full bg-[#7C117E] h-4 w-4" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <div className='w-full mt-10'>
                                    <button style={{
                                        display: user.isDriver || user.hasShop ? 'block' : 'none'
                                    }}
                                        onClick={() => {
                                            setType(
                                                type === 'User' && user.hasShop ? 'Vendor' :
                                                    type === 'User' && user.isDriver ? 'Courier' :
                                                        type === 'Vendor' && user.isDriver ? 'Courier' :
                                                            'User'
                                            )
                                            if (type === 'User' || type === 'Vendor') {
                                                setAction('Check Order History')
                                            } else {
                                                setAction('Check Delivery History')
                                            }
                                        }} className="border border-[#F3684D] choc-brown font-semibold rounded-md px-4 py-2 w-full">Check {
                                            type === 'User' && user.hasShop ? 'Vendor' :
                                                type === 'User' && user.isDriver ? 'Courier' :
                                                    type === 'Vendor' && user.isDriver ? 'Courier' :
                                                        'User'
                                        } Account</button>
                                    <button onClick={() => {
                                        setTab(action.replace('Check ', ''))
                                        setAction(
                                            action !== 'Check Transaction History' ? 'Check Transaction History' :
                                                type === 'User' || type === 'Vendor' ? 'Check Order History' :
                                                    'Check Delivery History'
                                        )
                                    }} className="border border-[#F3684D] choc-brown font-semibold rounded-md px-4 py-2 mt-4 w-full">{action}</button>
                                </div>
                            </div>
                        </div>

                        <div className="flex flex-col ml-20 w-5/6 overflow-hidden flex-grow overflow-y-auto">

                            {
                                tab === 'Transaction History' ?
                                    <table class="table-auto w-full overflow-y-auto">
                                        <thead className="inactive-text">
                                            <tr>
                                                <th className="text-start py-2 px-4">Transaction ID</th>
                                                <th className="text-start py-2 px-4">Date</th>
                                                <th className="text-start py-2 px-4">Time</th>
                                                <th className="text-start py-2 px-4">Status</th>
                                                <th className="text-start py-2 px-4">Name of recipient</th>
                                                <th className="text-start py-2 px-4">Anount</th>
                                                <th className="text-start py-2 px-4">Transaction</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {
                                                transactions.map((data, index) =>
                                                    <tr key={index}>
                                                        <td class="py-6 px-4">{data?.id}</td>
                                                        <td class="py-6 px-4">{data.createdAt.split("T")[0]}</td>
                                                        <td class="py-6 px-4">{new Date(data.createdAt).toLocaleTimeString([], {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit',
                                                            hour12: true,
                                                        })}</td>
                                                        <td style={{
                                                            color: data.status === 'SUCCESS' ? '#2F80ED' : '#EB5757'
                                                        }} class="py-6 px-4">{data.status}</td>
                                                        <td class="py-6 px-4">{data?.user.firstName} {data.user.lastName}</td>
                                                        <td class="py-6 px-4"><span className="choc-brown">NGN</span>{formattedCurrency(data?.amount).replace('NGN', '')}</td>
                                                        <td class="py-6 px-4">
                                                            {
                                                                data.type === 'DEBIT' ?
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#EB5757" class="bi bi-arrow-up-right" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M14 2.5a.5.5 0 0 0-.5-.5h-6a.5.5 0 0 0 0 1h4.793L2.146 13.146a.5.5 0 0 0 .708.708L13 3.707V8.5a.5.5 0 0 0 1 0z" />
                                                                    </svg>
                                                                    :
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#219653" class="bi bi-arrow-down-left" viewBox="0 0 16 16">
                                                                        <path fill-rule="evenodd" d="M2 13.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 0-1H3.707L13.854 2.854a.5.5 0 0 0-.708-.708L3 12.293V7.5a.5.5 0 0 0-1 0z" />
                                                                    </svg>
                                                            }
                                                        </td>
                                                    </tr>)
                                            }
                                        </tbody>
                                    </table>
                                    :
                                    <table class="table-auto w-full overflow-y-auto">
                                        <thead className="inactive-text">
                                            <tr>
                                                <th className="text-start py-2 px-4">Order No.</th>
                                                <th className="text-start py-2 px-4">{type !== 'Vendor' ? 'Vendor' : 'Customer'}</th>
                                                <th className="text-start py-2 px-4">Date</th>
                                                <th className="text-start py-2 px-4">Time</th>
                                                <th className="text-start py-2 px-4">Price</th>
                                                <th className="text-start py-2 px-4">Order Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="">
                                            {
                                                (type === 'User' ? orderHistory : type === 'Vendor' ? shopOrders : deliveryHistory)?.map((data, index) =>
                                                    <tr key={index}>
                                                        <td class="py-6 px-4">{data?.id}</td>
                                                        <td class="py-6 px-4">{type !== 'Vendor' ? data?.OrderShop?.map((item) => {
                                                            return item.shop.name + ', '
                                                        }) : `${data?.order?.user?.firstName} ${data?.order?.user?.lastName}`}</td>
                                                        <td class="py-6 px-4">{data.createdAt.split("T")[0]}</td>
                                                        <td class="py-6 px-4">{new Date(data.createdAt).toLocaleTimeString([], {
                                                            hour: '2-digit',
                                                            minute: '2-digit',
                                                            second: '2-digit',
                                                            hour12: true,
                                                        })}</td>
                                                        <td class="py-6 px-4"><span className="choc-brown">NGN</span>{formattedCurrency(type !== 'Vendor' ? data?.OrderShop?.map((item) => {
                                                            return type === 'User' ? item.amount : item.courierFee
                                                        }).reduce((acc, cur) => {
                                                            return acc + cur
                                                        }, 0) : data?.amount ?? 0).replace('NGN', '')}</td>
                                                        <td style={{
                                                            color: data.status === 'SUCCESS' ? '#2F80ED' : '#EB5757'
                                                        }} class="py-6 px-4">{data.status}</td>
                                                    </tr>)
                                            }
                                        </tbody>
                                    </table>
                            }
                        </div>
                    </div>
                </div>
                :
                <div className="flex w-full h-4/5 justify-center items-center">
                    <CircularProgressBar percentage={progress} />
                </div>
        }
            <Toaster />
        </>
    )
}

export default UserDetails


const getTransactions = async (setTransactions, token, id) => {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.transactions + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            setTransactions(data)
        }
    } catch (error) {
        console.log(error)
    }
}

const getOders = async (setOrders, token, id) => {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.orders + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log('orders', data)
        if (response.ok) {
            setOrders(data.data)
        }
    } catch (error) {
        console.log(error)
    }
}

const getDelivry = async (setDelevries, token, id) => {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.deleveries + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log('deleveries', data)
        if (response.ok) {
            setDelevries(data.data)
        }
    } catch (error) {
        console.log(error)
    }
}



const getShopOrders = async (setShopOrders, token, id) => {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.shopOders + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log('shop orders', data)
        if (response.ok) {
            setShopOrders(data.data)
        }
    } catch (error) {
        console.log(error)
    }
}


async function getUser(id, token, setUser) {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.user + `/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            setUser(data)
        }
    } catch (error) {
        console.log(error)
    }
}

async function verify(id, token, setUser) {
    try {
        const response = await fetch(endpoints.baseUrl + endpoints.verify + `${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
        })
        const data = await response.json()
        console.log(data)
        if (response.ok) {
            setUser(data)
        }
    } catch (error) {
        console.log(error)
    }
}