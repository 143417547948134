import React, { useState, useEffect } from 'react';
import CircularProgressBar from '../components/CircularProgressBar';


const Splash = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
      // Simulating progress increase over time
      const interval = setInterval(() => {
        setProgress((prev) => (prev < 100 ? prev + 1 : 0));
      }, 100);
  
      return () => clearInterval(interval);
    }, []);
    return (
        <div className="flex flex-col w-full h-screen justify-center items-center">
           <img
                src={require("../images/logo.png")}
                alt="logo"
               className="w-1/4 mb-12"
            />
            <CircularProgressBar percentage={progress} />
        </div>
    );
};

export default Splash;