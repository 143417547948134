import { PieChart } from 'react-minimal-pie-chart';

const Summary = ({users=[]}) => {
    const totalUsers = users.length
    const venders = users.filter(user => user.hasShop)
    const couriers = users.filter(user => user.isDriver)
    return (
        <div className="flex justify-around w-[1400px] px-6">
            <div className="border px-10 py-8 flex flex-col rounded border-[6px] rounded-lg border-[#F3684D] h-auto my-10">
                <div className="self-end text-[#F3684D] items-end flex flex-col">
                    <strong style={{
                        fontSize: '25px',
                    }}>Users</strong>
                    <p>{totalUsers} Users</p>
                </div>
                <div className='p-8 mt-4 bg-[#F3684D] rounded-full'>
                    <PieChart
                        data={[
                            { title: 'Offline', value: users.filter((it)=> it?.isOnline === false).length, color: 'white' },
                            { title: 'Online', value: users.filter((it)=> it?.isOnline).length, color: '#F3684D' },
                        ]}
                        // rounded
                        animate
                        style={{
                            height: '200px'
                        }}
                        
                        label={({ dataEntry }) => Math.round(dataEntry.percentage) + '% ' +dataEntry.title}
                        labelStyle={(index) => ({
                            fill: index === 1 ? 'white' : '#F3684D',
                            fontSize: '10px',
                        })}
                        labelPosition={40}
                    />
                </div>
            </div>

            <div className="border px-10 py-8 flex flex-col rounded border-[6px] rounded-lg border-[#EE5064] h-auto my-10">
                <div className="self-end text-[#EE5064] items-end flex flex-col">
                    <strong style={{
                        fontSize: '25px'
                    }}>Vendors</strong>
                    <p>{venders.length} Vendors</p>
                </div>
                <div className='p-8 mt-4 bg-[#EE5064] rounded-full'>
                    <PieChart
                        data={[
                            { title: 'Closed', value: venders.filter((it)=> it?.Shop?.isOpen === false).length, color: 'white' },
                            { title: 'Open', value: venders.filter((it)=> it?.Shop?.isOpen).length, color: '#EE5064' },
                        ]}
                        // rounded
                        animate
                        style={{
                            height: '200px'
                        }}
                        label={({ dataEntry }) => Math.round(dataEntry.percentage) + '% ' +dataEntry.title}
                        labelStyle={(index) => ({
                            fill: index === 1 ? 'white' : '#EE5064',
                            fontSize: '10px',
                        })}
                        labelPosition={40}
                    />
                </div>
            </div>

            <div className="border px-10 py-8 flex flex-col rounded border-[6px] rounded-lg border-[#7C117E] h-auto my-10">
                <div className="self-end text-[#7C117E] items-end flex flex-col">
                    <strong style={{
                        fontSize: '25px'
                    }}>Couriers</strong>
                    <p>{couriers.length} Couriers</p>
                </div>
                <div className='p-8 mt-4 bg-[#7C117E] rounded-full'>
                    <PieChart
                        data={[
                            { title: 'Offline', value: couriers.filter((it)=> it?.isOnline === false).length, color: 'white' },
                            { title: 'Online', value: couriers.filter((it)=> it?.isOnline).length, color: '#7C117E' },
                        ]}
                        // rounded
                        animate
                        style={{
                            height: '200px'
                        }}
                        label={({ dataEntry }) => Math.round(dataEntry.percentage) + '% ' +dataEntry.title}
                        labelStyle={(index) => ({
                            fill: index === 1 ? 'white' : '#7C117E',
                            fontSize: '10px',
                        })}
                        labelPosition={40}
                    />
                </div>
            </div>
        </div>
    )
}

export default Summary;